<template>
	<div class="position">
		<div class="position-wrap">
			<div class="position-wrap-top">
				<p>当前：{{ curSiteInfo.site_name }}</p>
			</div>
			<div class="position-wrap-ul">
				<p>开放城市</p>
				<ul>
					<li v-for="(item, index) in spotList" :key="index" @click="changeSiteCode(item.site_code)">
						{{ item.site_name }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		getSpotList,
		getSpotDetail
	} from "@/http/api"; //获取站点列表

	export default {
		data() {
			return {
				spotList: [] //站点列表
			};
		},
		computed: {
			...mapState(["curSiteInfo"])
		},
		mounted() {
			this.getSpotList();
		},
		methods: {
			// 获取站点列表
			async getSpotList() {
				const res = await getSpotList({
					data: {}
				});
				if (res.code == 200) {
					this.spotList = res.data;
				}
			},

			//点击站点, 获取点击站点信息
			async changeSiteCode(site_code) {
				const res = await getSpotDetail({
					data: {
						site_code: site_code
					}
				});
				if (res.code == 200) {
					this.$LStorage.setItem("curSiteInfo", res.data);
					this.$store.dispatch("changecurSiteInfo", res.data);

					setTimeout(() => {
						this.$router.replace("/home");
					}, 500);
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.position {
		width: 100%;
		min-height: 100vh;

		.position-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.position-wrap {
			width: 100%;
			min-height: 100vh;
			background: #f6f6f6;

			.position-wrap-top {
				display: flex;
				font-size: 0.23rem;
				border-bottom: 1px solid #e7e7e7;
				padding-left: 0.15rem;
				box-sizing: border-box;

				p {
					font-size: 0.26rem;
					color: #333333;
					height: 1.11rem;
					line-height: 1.11rem;
					margin-right: 0.12rem;
				}
			}
		}

		.position-wrap-ul {
			width: 100%;
			padding: 0.28rem;
			box-sizing: border-box;

			p {
				padding-bottom: 0.31rem;
				box-sizing: border-box;
				font-size: 0.26rem;
				color: #9a9a9a;
			}

			ul {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				li {
					width: 2.02rem;
					height: 0.79rem;
					background: #ffffff;
					border-radius: 0.05rem;
					font-size: 0.26rem;
					color: #333333;
					line-height: 0.79rem;
					text-align: center;
					margin-top: 0.26rem;
					margin-right: 0.15rem;
					border: #ececec solid 0.01rem;
					margin-bottom: 0.2rem;
				}

				li:nth-child(1) {
					margin-top: 0rem;
				}

				li:nth-child(2) {
					margin-top: 0rem;
				}

				li:nth-child(3) {
					margin-top: 0rem;
				}
			}
		}
	}
</style>
